import * as Yup from "yup";

const MAX_FILE_SIZE = 20971520;

const validFileExtensions = {
  image: ["jpg", "png", "jpeg", "pdf"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const getCharacterValidationError = (str) => {
  return `Require at least 1 ${str} character`;
};

export const contactFormValidationSchema = Yup.object({
  subject: Yup.string().required("This is a required field"),
  cardnumber: Yup.string().when("subject", {
    is: "Canco Cash Loyalty / Mobile App",
    then: () => Yup.string().required("This is a required field"),
  }),
  name: Yup.string().required("This is a required field"),
  message: Yup.string().required("This is a required field"),
  email: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  attachment: Yup.mixed()
    .test(
      "is-valid-type",
      "Upload valid image (.png, .jpg, .jpeg) or pdf",
      (value) =>
        value
          ? isValidFileType(value && value.name.toLowerCase(), "image")
          : true
    )
    .test("is-valid-size", "Max allowed size is 5MB", (value) => {
      // console.log("photo size", value);
      if (!value) return true;
      return value && value.size <= 5242880;
    }),
});

export const signupFormValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field"),
  confirmEmail: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field")
    .oneOf([Yup.ref("email")], "Email does not match"),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  password: Yup.string()
    .required("This is a required field")
    .min(8, "Require at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string()
    .required("This is a required field")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
  t_and_c_accepted: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});

export const signupCardValidationSchema = Yup.object({
  cardnumber: Yup.string()
    .matches(/^\d+$/, "Enter only number")
    .test("notStartsWith1234", "Gift card is not allowed", (value) => {
      return !value.startsWith("604958");
    })
    .required("Card number is required"),
  access_code: Yup.string().required("This is a required field"),
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field"),
  confirmEmail: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field")
    .oneOf([Yup.ref("email")], "Email does not match"),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  password: Yup.string()
    .required("This is a required field")
    .min(8, "Require at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string()
    .required("This is a required field")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
  t_and_c_accepted: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .required("This is a required field")
    .min(8, "Require at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string()
    .required("This is a required field")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});

export const addCardValidationSchema = Yup.object({
  cardnumber: Yup.string()
    .matches(/^\d+$/, "Card number must contain only digits")
    .test("notStartsWith1234", "Only loyalty card allowed", (value) => {
      return value.startsWith("637738") || value.startsWith("612573");
    })
    .required("Card number is required"),
  ackroo_id: Yup.string().required("This is a required field"),
});

export const balanceTransferValidationSchema = Yup.object({
  from_card_number: Yup.string().required("This is a required field"),
  to_card_number: Yup.string().required("This is a required field"),
});

export const dealerFormValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field"),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  gas_station_owner_or_operator: Yup.string().required(
    "This is a required field"
  ),
  address: Yup.string().when("owner", {
    is: (owner) => owner === "yes",
    then: () => Yup.string().required("This is a required field"),
  }),
  message: Yup.string().required("This is a required field"),
});

export const realtorFormValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  company_name: Yup.string().required("This is a required field"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field"),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  offering_lease_or_purchase: Yup.string().required("This is a required field"),
  address: Yup.string().required("This is a required field"),
  size_of_property: Yup.string().required("This is a required field"),
  zoning: Yup.string().required("This is a required field"),
  attachment: Yup.mixed().required("This is a required field"),
});

export const vendorStepOneValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This is a required field"),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  focused_product: Yup.string().required("This is a required field"),
  company_name: Yup.string().required("This is a required field"),
  distribution_model: Yup.string().required("This is a required field"),
});

export const vendorStepTwoValidationSchema = Yup.object({
  edi_capabilities: Yup.string().required("This is a required field"),
  minimum_order_levels: Yup.string().required("This is a required field"),
  how_to_order: Yup.string().required("This is a required field"),
  product_guarantee: Yup.string().required("This is a required field"),
  distribution_reach: Yup.string().required("This is a required field"),
  attachment: Yup.mixed()
    .test(
      "is-valid-type",
      "Upload valid image (.png, .jpg, .jpeg) or pdf",
      (value) =>
        value
          ? isValidFileType(value && value.name.toLowerCase(), "image")
          : true
    )
    .test("is-valid-size", "Max allowed size is 20MB", (value) => {
      // console.log("photo size", value);
      if (!value) return true;
      return value && value.size <= MAX_FILE_SIZE;
    }),
});

export const fleetMemberStepOneValidationSchema = Yup.object({
  business_name: Yup.string().required("This is a required field"),
  business_type: Yup.string().required("This is a required field"),
  years_of_company: Yup.string().required("This is a required field"),
  years_of_operation: Yup.string().required("This is a required field"),
  hst_exempt: Yup.string().required("This is a required field"),
  expected_fuel_volume: Yup.string().required("This is a required field"),
  no_of_cards_needed: Yup.string().required("This is a required field"),
});

export const fleetMemberStepTwoValidationSchema = Yup.object({
  street: Yup.string().required("This is a required field"),
  apartment: Yup.string().required("This is a required field"),
  city: Yup.string().required("This is a required field"),
  province: Yup.string().required("This is a required field"),
  postcode: Yup.string().required("This is a required field"),
  email: Yup.string()
    .email("Enter valid email")
    .required("This is a required field"),
  business_phone: Yup.string().required("This is a required field"),
  cell: Yup.string().required("This is a required field"),
});

export const fleetMemberStepThreeValidationSchema = Yup.object({
  contact_person: Yup.string().required("This is a required field"),
  contact_person_phone: Yup.string().required("This is a required field"),
  contact_person_email: Yup.string()
    .email("Enter valid email")
    .required("This is a required field"),
});

export const fleetMemberStepFourValidationSchema = Yup.object({
  first_name: Yup.string().required("This is a required field"),
  last_name: Yup.string().required("This is a required field"),
  dob: Yup.date().required("This is a required field"),
  street: Yup.string().required("This is a required field"),
  apartment: Yup.string().required("This is a required field"),
  city: Yup.string().required("This is a required field"),
  province: Yup.string().required("This is a required field"),
  postcode: Yup.string().required("This is a required field"),
  sin: Yup.string().required("This is a required field"),
});

export const fleetMemberStepFiveValidationSchema = Yup.object({
  financial_institution: Yup.string().required("This is a required field"),
  branch: Yup.string().required("This is a required field"),
  street: Yup.string().required("This is a required field"),
  apartment: Yup.string().required("This is a required field"),
  city: Yup.string().required("This is a required field"),
  province: Yup.string().required("This is a required field"),
  first_name: Yup.string().required("This is a required field"),
  last_name: Yup.string().required("This is a required field"),
  phone: Yup.string().required("This is a required field"),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Email is not valid")
    .required("This is a required field"),
  password: Yup.string().required("This is a required field"),
});

export const loginCardValidationSchema = Yup.object({
  email: Yup.string()
    .required("This is a required field")
    .matches(/^[0-9]+$/, "letters and spaces are not allowed")
    .min(18, "Minimum 18 digits required.")
    .max(18, "Maximum 18 digits required."),
  password: Yup.string().required("This is a required field"),
});

export const editUserProfileValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  phone: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
});

export const schoolSpiritValidationSchema = Yup.object({
  first_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  last_name: Yup.string()
    .required("This is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed "),
  phone_number: Yup.string()
    .required("This is a required field")
    .min(14, "Minimum 10 digits required."),
  email: Yup.string()
    .email("Email is not valid")
    .required("This is a required field"),
  card_number: Yup.string()
    .matches(/^\d+$/, "Card number must contain only digits")
    .test("notStartsWith1234", "Only loyalty card allowed", (value) => {
      console.log("card val", value);
      return value.startsWith("637738") || value.startsWith("612573");
    })
    .required("Card number is required"),
  school_name: Yup.string().required("This is a required field"),
  school_city: Yup.object().required("This is a required field"),
  school_province: Yup.object().required("This is a required field"),
});

export const surveyValidationSchema = Yup.object({
  overall_experience: Yup.string().required("This is a required field"),
  enviornment: Yup.string().required("This is a required field"),
  merchandise: Yup.string().required("This is a required field"),
  service_experience: Yup.string().required("This is a required field"),
  loyalty_card: Yup.string().required("This is a required field"),
});

export const accountDeletionValidationSchema = Yup.object({
  is_agree: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});

export const typeDeleteValidationSchema = Yup.object({
  name: Yup.string()
    .oneOf(
      ["DELETE"],
      "Sorry, please enter the text exactly as displayed to confirm"
    )
    .required("This field is required"),
});
